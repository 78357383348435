export default defineAppConfig({
  ui: {
    primary: 'black',
    gray: 'zinc',
    icons: {
      menu: 'i-mdi-menu',
    },
    tooltip: {
      // @ts-expect-error tooltip.default has type issues
      default: {
        openDelay: 500,
        closeDelay: 0,
      },
    },
    button: {
      default: {
        loadingIcon: 'i-mdi-sync',
      },
    },
    input: {
      default: {
        loadingIcon: 'i-mdi-sync',
      },
    },
    select: {
      default: {
        loadingIcon: 'i-mdi-sync',
        trailingIcon: 'i-mdi-chevron-down',
      },
    },
    selectMenu: {
      default: {
        selectedIcon: 'i-mdi-check',
      },
    },
    notification: {
      default: {
        closeButton: {
          icon: 'i-mdi-close',
        },
      },
    },
    notifications: {
      position: 'bottom-0 right-1/2 translate-x-1/2', // bottom-center
    },
    commandPalette: {
      default: {
        icon: 'i-mdi-magnify',
        loadingIcon: 'i-mdi-sync',
        selectedIcon: 'i-mdi-check',
        emptyState: {
          icon: 'i-mdi-magnify',
        },
      },
    },
    table: {
      default: {
        sortAscIcon: 'i-mdi-arrow-up',
        sortDescIcon: 'i-mdi-arrow-down',
        sortButton: {
          icon: 'i-mdi-menu-swap',
        },
        loadingState: {
          icon: 'i-mdi-sync',
        },
        emptyState: {
          icon: 'i-mdi-database',
        },
      },
    },
    pagination: {
      default: {
        firstButton: {
          icon: 'i-mdi-chevron-double-left',
        },
        prevButton: {
          icon: 'i-mdi-chevron-left',
        },
        nextButton: {
          icon: 'i-mdi-chevron-right',
        },
        lastButton: {
          icon: 'i-mdi-chevron-double-right',
        },
      },
    },
    accordion: {
      default: {
        openIcon: 'i-mdi-chevron-down',
      },
    },
    breadcrumb: {
      default: {
        divider: 'i-mdi-chevron-right',
      },
    },
  },
})
