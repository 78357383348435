import { default as roles8ZwhtfQ8gEMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/admin/auth/roles.vue?macro=true";
import { default as userszmIRR6Ga1bMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/admin/auth/users.vue?macro=true";
import { default as api61pqlzQrx4Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/admin/server/api.vue?macro=true";
import { default as blobYlInVf56CAMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/admin/storage/blob.vue?macro=true";
import { default as dbhuLwdADIXDMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/admin/storage/db.vue?macro=true";
import { default as kvMs0QDfBB01Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/admin/storage/kv.vue?macro=true";
import { default as authjGYuo4R863Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/auth.vue?macro=true";
import { default as index8NVxfUCrrJMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/content/applications/index.vue?macro=true";
import { default as indexT9PI0YLo3QMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/content/categories/index.vue?macro=true";
import { default as indexT6KH5413B6Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/content/industries/index.vue?macro=true";
import { default as indexyQ64w9mD4eMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/content/products/index.vue?macro=true";
import { default as _91_46_46_46slug_93btyJ9yTTG3Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/docs/[...slug].vue?macro=true";
import { default as indexWIw0vQHLoaMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/finance/banks/index.vue?macro=true";
import { default as uploadl1TXWt5vyIMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/finance/upload.vue?macro=true";
import { default as index24h9uDivALMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/index.vue?macro=true";
import { default as indexGa5ibpXmzGMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/management/accounts/index.vue?macro=true";
import { default as indexseh9YfOcDSMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/notifications/index.vue?macro=true";
import { default as index3RkKS2eMd4Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/purchases/contacts/index.vue?macro=true";
import { default as indexP1qMNtqo1OMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/purchases/inquiries/index.vue?macro=true";
import { default as indexVbQKXCGpJGMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/purchases/suppliers/index.vue?macro=true";
import { default as indexHEFgK7YrejMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/activities/index.vue?macro=true";
import { default as indexniGb12ecCyMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/campaigns/index.vue?macro=true";
import { default as indexpPZ9onfTDDMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/contacts/index.vue?macro=true";
import { default as activitieslyRV5YctgLMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/activities.vue?macro=true";
import { default as branchesAANYZyz3QCMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/branches.vue?macro=true";
import { default as contacts7vy2OgFxLKMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/contacts.vue?macro=true";
import { default as dealsn9bO7gPqJ5Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/deals.vue?macro=true";
import { default as index16zsCEFpsFMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/index.vue?macro=true";
import { default as noteslQcKFOamthMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/notes.vue?macro=true";
import { default as _91id_93R1glncJ6F6Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id].vue?macro=true";
import { default as indexohtOGyL7qdMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/index.vue?macro=true";
import { default as index2b3hTx48aSMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/deals/index.vue?macro=true";
import { default as indexcXTE9s6ZAWMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/inquiries/index.vue?macro=true";
import { default as indexT5krN4MNfVMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/purchaseorders/index.vue?macro=true";
import { default as indexJbIfbWhNWpMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/sales/quotes/index.vue?macro=true";
import { default as indexenWINisYB5Meta } from "/home/gabriel-romero/repos/esgraf/app/pages/settings/index.vue?macro=true";
import { default as notificationsiaCGELMMHqMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/settings/notifications.vue?macro=true";
import { default as settingshpmbvaT1tdMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/settings.vue?macro=true";
import { default as indexiasNmUvrpUMeta } from "/home/gabriel-romero/repos/esgraf/app/pages/tasks/index.vue?macro=true";
import { default as component_45stubuwCc2fETeNMeta } from "/home/gabriel-romero/repos/esgraf/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuwCc2fETeN } from "/home/gabriel-romero/repos/esgraf/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-auth-roles",
    path: "/admin/auth/roles",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/admin/auth/roles.vue").then(m => m.default || m)
  },
  {
    name: "admin-auth-users",
    path: "/admin/auth/users",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/admin/auth/users.vue").then(m => m.default || m)
  },
  {
    name: "admin-server-api",
    path: "/admin/server/api",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/admin/server/api.vue").then(m => m.default || m)
  },
  {
    name: "admin-storage-blob",
    path: "/admin/storage/blob",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/admin/storage/blob.vue").then(m => m.default || m)
  },
  {
    name: "admin-storage-db",
    path: "/admin/storage/db",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/admin/storage/db.vue").then(m => m.default || m)
  },
  {
    name: "admin-storage-kv",
    path: "/admin/storage/kv",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/admin/storage/kv.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: authjGYuo4R863Meta || {},
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "content-applications",
    path: "/content/applications",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/content/applications/index.vue").then(m => m.default || m)
  },
  {
    name: "content-categories",
    path: "/content/categories",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/content/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "content-industries",
    path: "/content/industries",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/content/industries/index.vue").then(m => m.default || m)
  },
  {
    name: "content-products",
    path: "/content/products",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/content/products/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "finance-banks",
    path: "/finance/banks",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/finance/banks/index.vue").then(m => m.default || m)
  },
  {
    name: "finance-upload",
    path: "/finance/upload",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/finance/upload.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "management-accounts",
    path: "/management/accounts",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/management/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "purchases-contacts",
    path: "/purchases/contacts",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/purchases/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "purchases-inquiries",
    path: "/purchases/inquiries",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/purchases/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: "purchases-suppliers",
    path: "/purchases/suppliers",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/purchases/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-activities",
    path: "/sales/activities",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/activities/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-campaigns",
    path: "/sales/campaigns",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-contacts",
    path: "/sales/contacts",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R1glncJ6F6Meta?.name,
    path: "/sales/customers/:id()",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "sales-customers-id-activities",
    path: "activities",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/activities.vue").then(m => m.default || m)
  },
  {
    name: "sales-customers-id-branches",
    path: "branches",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/branches.vue").then(m => m.default || m)
  },
  {
    name: "sales-customers-id-contacts",
    path: "contacts",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/contacts.vue").then(m => m.default || m)
  },
  {
    name: "sales-customers-id-deals",
    path: "deals",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/deals.vue").then(m => m.default || m)
  },
  {
    name: "sales-customers-id",
    path: "",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-customers-id-notes",
    path: "notes",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/[id]/notes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "sales-customers",
    path: "/sales/customers",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-deals",
    path: "/sales/deals",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/deals/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-inquiries",
    path: "/sales/inquiries",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-purchaseorders",
    path: "/sales/purchaseorders",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/purchaseorders/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-quotes",
    path: "/sales/quotes",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/sales/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: settingshpmbvaT1tdMeta?.name,
    path: "/settings",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/home/gabriel-romero/repos/esgraf/app/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubuwCc2fETeNMeta?.name,
    path: "/admin",
    component: component_45stubuwCc2fETeN
  },
  {
    name: component_45stubuwCc2fETeNMeta?.name,
    path: "/content",
    component: component_45stubuwCc2fETeN
  },
  {
    name: component_45stubuwCc2fETeNMeta?.name,
    path: "/docs",
    component: component_45stubuwCc2fETeN
  },
  {
    name: component_45stubuwCc2fETeNMeta?.name,
    path: "/finance",
    component: component_45stubuwCc2fETeN
  },
  {
    name: component_45stubuwCc2fETeNMeta?.name,
    path: "/management",
    component: component_45stubuwCc2fETeN
  },
  {
    name: component_45stubuwCc2fETeNMeta?.name,
    path: "/purchases",
    component: component_45stubuwCc2fETeN
  },
  {
    name: component_45stubuwCc2fETeNMeta?.name,
    path: "/sales",
    component: component_45stubuwCc2fETeN
  }
]