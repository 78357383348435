<script setup lang="ts">
const appConfig = useAppConfig()
const route = useRoute()
const colorMode = useColorMode()
const { loggedIn } = useUserSession()

watch(loggedIn, () => {
  if (!loggedIn.value) navigateTo('/auth')
})

const color = computed(() => (colorMode.value === 'dark' ? '#111827' : 'white'))

useHead({
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color },
    // route.meta defined with definePageMeta({ title }) at each page
    { property: 'og:title', content: `${appConfig.title} - ${route.meta.title}` },
  ],
  link: [{ rel: 'icon', href: '/favicon.svg' }],
  htmlAttrs: {
    lang: 'es',
  },
})

useSeoMeta({
  title: appConfig.title,
  description: appConfig.description,
  ogTitle: appConfig.title,
  ogDescription: appConfig.description,
  ogImage: 'https://esgraf.nuxt.dev/social-card.png',
  twitterImage: 'https://esgraf.nuxt.dev/social-card.png',
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />
    <UModals />
    <USlideovers />
  </div>
</template>
