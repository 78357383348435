<script setup lang="ts">
import type { NuxtError } from '#app'

useHead({
  htmlAttrs: {
    lang: 'es',
  },
})

useSeoMeta({
  title: 'Pagina no encontrada',
  description: 'Lo sentimos pero esta pagina no pudo ser encontrada.',
})

defineProps<{
  error: NuxtError
}>()
</script>

<template>
  <div>
    <UMain>
      <UContainer>
        <UPage>
          <UPageError :error="error" />
        </UPage>
      </UContainer>
    </UMain>

    <UNotifications />
  </div>
</template>
